import { serverBus } from '@/main';
import { API } from '@/inc/api';

export const reports = {
    namespaced: true,
    state: {
        reports     : [],
        needRefresh : true,
    },
    mutations: {
        setReports( state, payload ) {
            state.reports = payload;
        },
    },
    actions: {
        async load({ commit, state }) {
            if( state.reports.length > 0 ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/reports');

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setReports', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
    },
    getters: {
        reports     : (state) => state.reports,
    }
}